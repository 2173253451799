import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "gatsby-plugin-react-i18next";
import Typography from "@mui/material/Typography";
import Box, {BoxProps} from "@mui/material/Box";
import SiteLayout from "components/layouts/SiteLayout";
import Section from "components/molecules/Section";
import SectionTitle from "components/atoms/SectionTitle";

const SectionContent = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      "& h2": {
        px: {sx: 2, md: 4, lg: 6},
        pt: {sx: 2, md: 4, lg: 6}
      },
      "& p": {
        px: {sx: 2, md: 4, lg: 6}
      },
      ...props.sx
    }}
  />
);

export default function TermsAndConditions() {
  return (
    <SiteLayout>
      <Helmet>
        <title>DokDok - Regulaminy i warunki</title>
      </Helmet>
      <Section>
        <SectionTitle>
          Regulaminy i warunki
        </SectionTitle>
        <SectionContent>
          <Typography variant="h2">
            Regulaminy
          </Typography>
          <Typography>
            <ul>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/regulamin.pdf`}
                >
                  Regulamin
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/standard-udzielania-teleporad.pdf`}
                >
                  Standard udzielania teleporad
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/21-02-01_Regulamin_organizacyjny_POZ.pdf`}
                >
                  Regulamin organizacyjny podmiotu leczniczego
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/regulamin-programu-polecen.pdf`}
                >
                  Regulamin programu poleceń
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/dokdok_habittracker.pdf`}
                >
                  Szablon do monitorowania nawyków zdrowotnych
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/23-06-21_regulamin_promocji_z_Legimi.pdf`}
                >
                  Regulamin promocji Czytam z DokDok i Legimi!
                </a>
              </li>
            </ul>
          </Typography>
          <Typography variant="h2">
            Dane Osobowe
          </Typography>
          <Typography>
            <ul>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/polityka-prywatnosci-dokdok.pdf`}
                >
                  Polityka prywatności
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/Kaluzula_informacyjna_dotycząca_przetwarzania_danych_osobowych_w_celach_marketingowych.pdf`}
                >
                  Kaluzula informacyjna dotycząca przetwarzania danych osobowych w celach marketingowych
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/Klauzula_informacyjna_dotycząca_przetwarzania_danych_osobowych_pacjentów.pdf`}
                >
                  Klauzula informacyjna dotycząca przetwarzania danych osobowych pacjentów
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/Klauzula_informacyjna_dotycząca_przetwarzania_danych_osobowych_w_korespondencji_elektronicznej.pdf`}
                >
                  Klauzula informacyjna dotycząca przetwarzania danych osobowych w korespondencji elektronicznej
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/Klauzula_informacyjna_marketing_elektroniczny.pdf`}
                >
                  Klauzula informacyjna marketing elektroniczny
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/Klauzula_informacyjna_dotycząca_przetwarzania_danych_osobowych_w_celach_biznesowych.pdf`}
                >
                  Klauzula informacyjna dotycząca przetwarzania danych osobowych w celach biznesowych
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/Klauzula_informacyjna_dotycząca_przetwarzania_danych_osobowych_osób_ubiegających_się_o_pracę.pdf`}
                >
                  Klauzula informacyjna dotycząca przetwarzania danych osobowych osób ubiegających się o pracę
                </a>
              </li>
            </ul>
          </Typography>
          <Typography variant="h2">Miejsce i godziny udzielania świadczeń</Typography>
          <Typography>
            <ul>
              <li>Przychodnia DokDok al. Armii Krajowej 46ad 50-541 Wrocław, jest czynna od poniedziałku do piątku w
                godzinach 8.00-18.00
              </li>
              <li>Przychodnia DokDok ul. Postępu 15c 02-676 Warszawa, jest czynna od poniedziałku do piątku w godzinach
                8:00-18:00
              </li>
            </ul>
          </Typography>
          <Typography variant="h2">Udogodnienia dla osób niepełnosprawnych.</Typography>
          <Typography>
            <ul>
              <li>
                przychodnia jest w pełni dostępna dla osób niepełnosprawnych (brak barier architektonicznych)
              </li>
              <li>
                dodatkowo dla osób niesłyszących konsultacje zdalne przeprowadzane są w formie czatu.
              </li>
            </ul>
          </Typography>
        </SectionContent>
      </Section>
    </SiteLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

